/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { EthIcon } from "../../container/Popup/Icon";
import { Close, Minus, Plus, CloseTwo } from "../icons/Icon";
import logo from "../../images/small-icon.png";
import img1 from "../../images/image-1.png";
import img2 from "../../images/image-2.png";
import img3 from "../../images/image-3.png";
import img4 from "../../images/image-4.png";
import more from "../../images/more.png";
import { flipeet } from "../../lib/flipeet";

import {
  CloseBtn,
  LeftSide,
  MintSection,
  ModalContainer,
  ModalContent,
  RightSide,
  UseCase,
  MobileContent,
  Footer,
} from "./ModalCss";

const ImageData = [
  { id: 1, image: img1 },
  { id: 2, image: img2 },
  { id: 3, image: img3 },
  { id: 4, image: img4 },
  { id: 5, image: img1 },
];

const MintModal = ({ handleMintModal, price, getUserDetails }) => {
  const [num, setNum] = useState(1);
  const [amountMinted, setAmountMinted] = useState("");
  const tokenId = 1;
  const amount = num;

  const handleMint = async () => {
    await flipeet.nativetoken.mint(tokenId, amount);
    getUserDetails();
  };
  const getMintedAmount = async () => {
    const amountLeft = await flipeet.nativetoken
      .getAmountMinted(1)
      .then((res) => {
        setAmountMinted(res.toNumber());
      });
  };

  useEffect(() => {
    getMintedAmount();
  }, []);

  const decrease = () => {
    if (num > 1) {
      setNum((count) => count - 1);
    }
  };
  const increase = () => {
    if (num === 5) return;
    setNum((count) => count + 1);
  };

  return (
    <ModalContainer onClick={handleMintModal}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <CloseBtn onClick={handleMintModal}>
          <CloseTwo />
        </CloseBtn>
        <LeftSide>
          <div className="flipeet__video_container-div">
            <video
              src="https://ipfs.io/ipfs/QmR1Y6TwgtFZwPRUgv6mwG3YiGivA8hmnUs1ZiqsYmpQU3"
              controls
              loop
              autoPlay
              muted
            />
          </div>
          <div className="flipeet__mint_modal_nft_price">
            <EthIcon /> &nbsp; {price * num}
          </div>
          <div className="flipeet__mint_modal_nft_collection">
            <div>
              <h6>Collection:</h6> &nbsp;
              <img src={logo} alt="" /> &nbsp;
              <p>Flipeet Community</p>
            </div>
            <div>
              <h6>Owners:</h6> &nbsp;
              {ImageData.map((img) => (
                <img src={img.image} alt="nft-sample" key={img.id} />
              ))}
              &nbsp;
              <img src={more} alt="" />
            </div>
          </div>
        </LeftSide>
        <RightSide>
          <div>
            <span>Flipeet Community NFT</span>
            <div className="flipeet__flex_class">
              <h3>TIMELESS</h3> &nbsp;
              <h5>({amountMinted}/10,000)</h5>
            </div>
            <small>Max NFT per wallet = 5</small>
          </div>
          {/* <UseCase>
            <h4>NFT Use cases</h4>

            <ul>
              <li> Automatic beta testing slots for holders</li>
              <li>Chance to get weekly NFT drops</li>
              <li> 2.3% transaction fee </li>
              <li> 1.9% transaction fee on NFT to NFT flips</li>
            </ul>
          </UseCase> */}

          <MintSection>
            <div className="flipeet_nft_control_btn">
              <aside>
                <div onClick={decrease}>
                  <Minus />
                </div>
                <p>{num}</p>
                <div onClick={increase}>
                  <Plus />
                </div>
              </aside>{" "}
              &nbsp;
              <h6>(Max mint per transaction = 5)</h6>
            </div>

            <button onClick={handleMint}> Mint</button>
          </MintSection>
        </RightSide>
      </ModalContent>

      {/* mobilepopup */}

      <MobileContent onClick={(e) => e.stopPropagation()}>
        <div className="flipeet_mobile_popup_header">
          <span>Flipeet Community NFT</span>
          <div onClick={handleMintModal}>
            <Close />
          </div>
          {/* <Close /> */}
        </div>
        <div className="flipeet_mobile_popup_top_section">
          <h3>
            TIMELESS <span>({amountMinted}/10,000)</span>
          </h3>
          <div className="flipeet__mobile_popup_video_container_div">
            <video
              src="https://ipfs.io/ipfs/QmR1Y6TwgtFZwPRUgv6mwG3YiGivA8hmnUs1ZiqsYmpQU3"
              controls
              loop
              autoPlay
              muted
            />
          </div>
          <h6>
            <EthIcon /> &nbsp; {price}
          </h6>
          <small>Max NFT per wallet = 5</small>
          <aside>
            <div>
              <h6>Collection:</h6> &nbsp;
              <img src={logo} alt="" /> &nbsp;
              <p>Flipeet Community</p>
            </div>
            <div>
              <h6>Owners:</h6> &nbsp;
              {ImageData.map((img) => (
                <img src={img.image} alt="nft-sample" key={img.id} />
              ))}
              &nbsp;
              <img src={more} alt="" />
            </div>
          </aside>
        </div>
        {/* 
        <div className="flipeet_mobile_popup_bottom_section">
          <h4>NFT Use cases</h4>

          <ul>
            <li> Automatic beta testing slots for holders</li>
            <li>Chance to get weekly NFT drops</li>
            <li> 2.3% transaction fee </li>
            <li> 1.9% transaction fee on NFT to NFT flips</li>
          </ul>
        </div> */}
        <Footer>
          <section>
            <aside>
              <div onClick={decrease}>
                <Minus />
              </div>
              <p>{num}</p>
              <div onClick={increase}>
                <Plus />
              </div>
            </aside>

            <div className="flipeet_mobile_popup_nft_details">
              <h5>(Max mint per transaction = 5)</h5>
              <h6>
                <EthIcon /> &nbsp; {price * num}
              </h6>
            </div>
            <button onClick={handleMint}>Mint</button>
          </section>
        </Footer>
      </MobileContent>
    </ModalContainer>
  );
};
export default MintModal;
