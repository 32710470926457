import React from "react";
import styled from "styled-components";
import bgImg from "../../images/success.svg";
import { Close } from "../icons/Icon";

const SuscribedModal = ({ setSuccess }) => {
  const handleModal = () => {
    setSuccess(false);
  };

  return (
    <ModalContainer onClick={handleModal}>
      <Content onClick={(e) => e.stopPropagation()}>
        <CloseDiv>
          <Close />
        </CloseDiv>
        <h4>Success!</h4>
        <section>
          <h6>You have subscribed to our newsletter!</h6>
          <p>
            Look out for our first email. Remember you can unsubscribe anytime
          </p>
          <button onClick={handleModal}>Great!</button>
        </section>
      </Content>
    </ModalContainer>
  );
};

export default SuscribedModal;

const CloseDiv = styled.div`
  width: 27.98px;
  height: 27.98px;
  background: #ffffff;
  position: absolute;
  right: -20px;
  top: -30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  border-radius: 50%;
  @media (max-width: 768px) {
    top: -10px;
    right: -10px;
  }
`;
const ModalContainer = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(4, 23, 22, 0.5);
  backdrop-filter: blur(15px);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
`;

const Content = styled.div`
  width: 493.58px;
  height: 394.79px;
  z-index: 100;
  background: #ffffff;
  border-radius: 14.2039px;
  position: relative;
  padding: 30px;
  background: url(${bgImg}) no-repeat center/cover;
  position: relative;

  h4 {
    position: absolute;
    top: 86px;
    left: 166px;

    // typography
    font-family: "Gerbil";
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    color: #ffffff;
  }
  h5 {
    margin-top: 30px;
    font-family: "M PLUS 1";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-decoration-line: underline;
    color: rgba(4, 23, 22, 0.75);
  }

  span {
    white-space: nowrap;
    display: inline-block;
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    /* border: 1px solid #000000; */
  }

  section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 203px;
    width: 100%;
    left: 0;

    h6 {
      font-family: "M PLUS 1";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;
      color: #041716;
      width: 300px;
      align-items: center;
    }

    p {
      margin-top: 10px;
      font-family: "M PLUS 1";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #1d0f0f;
      width: 398px;
    }

    button {
      margin-top: 30px;
      width: 95.14px;
      height: 45px;
      background: #041716;
      box-shadow: 0px 2.84078px 14.2039px -2.13058px rgba(0, 0, 0, 0.25);
      border-radius: 35.5097px;
      border: none;
      outline: none;
      // typography
      font-family: "M PLUS 1";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #ffffff;
    }
  }

  @media (max-width: 768px) {
    min-width: 90%;
    max-width: 355.08px;
    height: 500px;
    background-image: url(${bgImg});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @media (max-width: 640px) {
      height: 400px;
      background-repeat: no-repeat;
      background-position: left;
      background-size: cover;
    }

    h4 {
      left: 50%;
      transform: translate(-50%, 0);
    }

    section {
      bottom: 50px;
      h6 {
        width: 80%;
        text-align: center;
      }
      p {
        width: 80%;
      }
      h5 {
        margin-top: 20px;
      }
      form {
        flex-direction: column;
        align-items: center;
        input[type="text"] {
          min-width: 95%;
          max-width: 314px;
        }
        button {
          margin-top: 20px;
        }
      }
    }
  }
`;
