import React from "react";
import "./Home.css";

// Component imports
// import Header from "../../container/Header/Header";
import Footer from "../../container/Footer/Footer";
import Tagline from "../../container/Tagline/Tagline";
import Features from "../../container/Features/Features";
import Contact from "../../container/Contact/Contact";
import Popup from "../../container/Popup/Popup";
import NewHeader from "../../container/Header/NewHeader";

const Home = () => {
  return (
    <div className="home">
      <Popup />
      <NewHeader />
      <Tagline />
      <Features />
      <Contact />
      <Footer />
    </div>
  );
};

export default Home;
