import styled from "styled-components";
import bgImg from "../../images/p-bg-image.png";

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
`;

export const Main = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
  position: relative;
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  padding: 0 35px;

  @media (min-width: 768px) {
    padding: 0 70px;
  }
  @media (min-width: 1024px) {
    flex-direction: row-reverse;
    justify-content: space-between;
    height: 60vh;
    justify-content: center;
    align-items: center;
    align-items: unset;
    justify-self: unset;
  }

  @media (min-width: 1280px) {
    height: 100vh;
  }
`;

export const Hero = styled.div`
  width: 100%;
  height: 490px;
  display: flex;
  justify-content: center;

  .flipeet_nft_title_container {
    position: absolute;
    top: 10px;
    text-align: center;
    font-family: "Neris";
    font-style: normal;
    // font-weight: 300;
    font-size: 12.9472px;
    line-height: 17px;
    text-align: center;
    color: #000000;

    p {
      font-weight: 300;
    }
    h5 {
      font-weight: 600;
    }
  }

  .flipeet_nft_price_container {
    position: absolute;
    top: 15px;
    right: 0px;
    width: 60.54px;
    height: 60.54px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    border: 0.66521px solid #abb2b1;
    border-radius: 50%;
    /* typography */
    font-family: "M PLUS 1";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #041716;
  }

  .video-container {
    width: 80%;
    height: 80%;
    position: relative;
    border-radius: 50%;
    display: flex;
    align-self: end;
    overflow: hidden;
  }
  img {
    width: 80%;
    height: 490px;
    position: absolute;
    object-fit: contain;
    top: 0;
    right: 0;
  }
  .contain {
    position: relative;
    align-self: end;
    justify-self: end;
    height: 250px;
    width: 250px;
    margin-bottom: 50px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 13.2399px 22.0666px 3.30999px rgba(0, 0, 0, 0.15);

    video {
      width: 100%;
      height: 100%;
      position: absolute;
      border-radius: 50%;
      top: 0;
      left: 10px;
      right: 0px;
      object-fit: contain;
      -moz-transform: scale(2);
      -webkit-transform: scale(2);
      -o-transform: scale(2);
      -ms-transform: scale(2);
      transform: scale(2);
    }
    button {
      background: #ffffff;
      border: 0.573232px solid rgba(255, 174, 36, 0.75);
      box-shadow: 0px 3.43939px 11.4646px rgba(0, 0, 0, 0.1);
      border-radius: 9.57986px;
      width: 125px;
      height: 33px;
      position: absolute;
      bottom: -7px;
    }

    button:hover {
      transform: scale(1.05);
    }
  }

  @media (min-width: 1024px) {
    width: 50%;
    height: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: unset;

    img {
      width: 50%;
      height: 95%;
      object-fit: contain;
    }

    .contain {
      width: 450.08px;
      height: 450.08px;
      margin-bottom: unset;
      align-self: center;
      justify-self: center;
      margin-top: 100px;
      video {
        left: 18px;
      }
      button {
        align-self: baseline;
      }
    }
  }

  @media (min-width: 768px) {
    height: 100%;
    margin-top: 100px;

    img {
      width: 70%;
      height: 550px;
      position: absolute;
      top: 0;
      right: 0;
    }

    .contain {
      width: 400.08px;
      height: 400.08px;
      video {
        left: 15px;
      }
      button {
        width: 131px;
        height: 35px;
        border-radius: 10px;
        font-family: "M PLUS 1";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #041716;
      }
    }
    .flipeet_nft_price_container {
      width: 92.54px;
      height: 92.54px;
    }
  }
`;

export const LeftSide = styled.div`
  margin-top: 50px;
  h3 {
    font-family: "Gerbil";
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 30px;
    color: #041716;
  }
  h4 {
    display: none;
  }

  .hero-subtex {
    background-image: url(${bgImg});
    background-repeat: no-repeat;
    padding: 2rem 0.7rem;
    font-size: 12px;
    line-height: 16px;
    background-position: 0% 23%;
    background-size: contain;
  }

  button {
    width: 139.81px;
    height: 36.66px;
    background: transparent;
    border: 1.24277px solid #ffae24;
    border-radius: 15.5346px;
    font-family: "Neris";
    font-style: normal;
    font-weight: 900;
    font-size: 12px;
    line-height: 16px;
    color: #ffae24;
    margin-bottom: 66px;
    align-self: baseline;
    cursor: pointer;
  }

  @media (min-width: 768px) {
    .hero-subtex {
      width: 100%;
      background-size: contain;
    }

    button {
      width: 225px;
      height: 59px;
      border: 2px solid #ffae24;
      border-radius: 25px;
      font-family: "Neris";
      font-style: normal;
      font-weight: 900;
      font-size: 20px;
      line-height: 27px;
      /* identical to box height */

      /* Flipeet Yellow */

      color: #ffae24;
    }
  }

  @media (min-width: 1024px) {
    margin-top: 100px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 45%;
    h3 {
      display: none;
    }
    h4 {
      display: block;
      font-family: "Gerbil";
      font-style: normal;
      font-weight: 400;
      font-size: 40px;
      line-height: 50px;
      color: #041716;
    }
    .hero-subtex {
      padding: 4rem 1rem;
      /* font-weight: 500; */
      font-size: 20px;
      line-height: 30px;
      font-family: "M PLUS 1", sans-serif;
      color: var(--font-color-dark);
      background-image: url(../../images//Group\ 414.png);
      background-repeat: no-repeat;
      background-position: 0% 20%;
    }
  }
`;

// export { Container, LeftSide, Hero, Main };
