import React, {useState} from 'react'
import './Popup.css'
import { getFirestore, collection, getDocs, setDoc, doc  } from "firebase/firestore";
import { app } from '../../FirebaseConfig';
import Success from '../../components/success/Success';

const Popup = ({ isOpen, setIsOpen }) => {
  const [email, setEmail] = useState('');
  const [successModal, setSuccessModal] = useState(false);

  const [btnText, setBtnText] = useState('Submit');

  const db = getFirestore(app);

  const handleChange = (e) => {
    setEmail(e.target.value);
  }

  const handleClick = () => {
    setIsOpen(false);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    setBtnText('Submitting...');

    const subscriptionRef = collection(db, `Subscription`); ///get the booking collection
		const snaps = await getDocs(subscriptionRef); //get the documents in the collection
		console.log(snaps);

		if (!snaps.exists) {
			const createdAt = new Date();

			try {
				console.log('saving...')
				await setDoc(doc(db, 'Subscription', `${email}`),  {
					email,
					createdAt
				});
        e.target.reset();
        setIsOpen(false);
        setSuccessModal(true);
			} catch (error) {
				console.log("Error Creating User", error.message);
			}
      setEmail('');
       setBtnText('Submit');
		}
  }
  return (
    <div>
      {
        isOpen ? (
          <div className='popup'>
          <div className='popup-content'>
            <div className='close-container' onClick={handleClick}><span className="popup-close">&times;</span></div>
              <div className='popup-header'>
                <p>We are <span className='line-break'>coming soon!</span></p>
              </div>
              <div className='popup-body'>
                <p>And we’ll be glad to have you</p>
                <p>Join the waiting list and get updates on our<br></br> development process by subscribing to our newsletter </p>
              </div>
              <form onSubmit={handleSubmit} className='popup-footer'>
                <input type='email' placeholder='Enter your email' onChange={handleChange} />
                <button>{btnText}</button>
              </form>
            </div>
          </div>
        ) : null
      }
      <Success isOpen={successModal} setIsOpen={setSuccessModal}/>
    </div>
  )
}

export default Popup