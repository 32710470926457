import React from 'react'
import './Features.css'
import Star from '../../images/star.svg'
import WalletImg from '../../images/wallet 3.svg';
import Transfer from '../../images/ape 3.svg'
import Convert from '../../images/conversion 4.svg'

const Features = () => {
  return (
    <section className='features' id='features'>
      <div>
        <div className='first-feature_bg'>
          <div className='header-text flex section-padding'>
            <div>
              <img src={Star} alt='core features' />
            </div>
            <h2>Our Core Features</h2>
          </div>
          <div className='section-padding first-feature feature'>
            <div className='mobile-image'>
              <img src={WalletImg} alt='wallets' />
            </div>
            <div className='text-container first'>
              <h2 className='main-text'>Connects <br></br>crypto wallets</h2>
              <p className='sub-text'>Flipeet supports a good number of <br></br>popular 721 and ERC-20 crypto wallets.</p>
            </div>
          </div>
        </div>
        <div className='section-padding second-feature feature flex'>
          <div className='text-container second'>
            <div className='mobile-image'>
              <img src={Transfer} alt='wallets' />
            </div>
            <h2 className='main-text'>Makes provision <br></br>for NFT bartering</h2>
            <p className='sub-text'>Participate in acquiring NFTs by offering your NFTs, ERC-20<br></br> tokens, or both in exchange for your favourite listed NFTs, and<br></br> make your own listings.</p>
          </div>
        </div>
        <div className='section-padding third-feature feature'>
          <div className='mobile-image'>
              <img src={Convert} alt='wallets' />
            </div>
          <div className='text-container third'>
            <h2 className='main-text'>Allows exchanges<br></br>of multiple Nfts for <br></br>one</h2>
            <p className='sub-text'>Flipeet allows for multiple tokens (NFTs and ERC-20 <br></br>tokens) to be offered as one in exchange for a listed NFT.</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Features