import React from 'react'
import './Success.css'

const Success = ({ isOpen, setIsOpen }) => {
  const handleClick = () => {
    setIsOpen(false);
  }
  return (
    <div>
      {
        isOpen ? (
          <div className='popup'>
          <div className='success-content'>
            <div className='sucess-close-container' onClick={handleClick}><span className="success-close">&times;</span></div>
              <div className='success-header'>
                <p>Success!</p>
              </div>
              <div className='popup-body'>
                <p className='success-main_text'>You have subscribed to our newsletter!<span>🎉</span></p>
                <p>Look out for our first email. Remember you can <br></br>unsubscribe anytime</p>
              </div>
              <div onClick={handleClick} className='success-footer'>
                <button>Great!</button>
              </div>
            </div>
          </div>
        ) : null
      }
    </div>
  )
}

export default Success