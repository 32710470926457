import React, { useState, useEffect } from "react";
import styled from "styled-components";
import img from "../../images/connect.svg";
import { Close } from "../icons/Icon";
import {
  getFirestore,
  collection,
  getDocs,
  setDoc,
  doc,
} from "firebase/firestore";
import { app } from "../../FirebaseConfig";
import { flipeet } from "../../lib/flipeet";

const ConnectedModal = ({ setConnected, user, setSuccess, text }) => {
  const [email, setEmail] = useState("");
  const [btnText, setBtnText] = useState("Subscribe");
  const [truncatedText, setTruncatedText] = useState("");
  const handleChange = (e) => {
    setEmail(e.target.value);
  };
  const handleModal = () => {
    setConnected(false);
  };

  useEffect(() => {
    if (user?.ethAddress) {
      setTruncatedText(flipeet.utils.getEllipsisTxt(user.ethAddress, 5));
    }
  }, [user]);

  const db = getFirestore(app);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setBtnText("Submitting...");

    const subscriptionRef = collection(db, `Subscription`); ///get the booking collection
    const snaps = await getDocs(subscriptionRef); //get the documents in the collection

    if (!snaps.exists) {
      const createdAt = new Date();

      try {
        await setDoc(doc(db, "Subscription", `${email}`), {
          email,
          createdAt,
        });
        e.target.reset();
        handleModal();
        setSuccess(true);
      } catch (error) {
        console.log("Error Creating User", error.message);
      }
      setEmail("");
      setBtnText("Submit");
    }
  };
  return (
    <ModalContainer onClick={handleModal}>
      <Content onClick={(e) => e.stopPropagation()}>
        <CloseDiv onClick={handleModal}>
          <Close />
        </CloseDiv>
        <h4>{text}</h4>
        <section>
          <h6>
            {text === "Congratulations!"
              ? `Your wallet ${truncatedText} has been successfully
            connected!`
              : " Wallet connection failed"}
          </h6>
          <p>
            Subscribe to our newsletter to be updated when our dApp is live!
          </p>
          <form onSubmit={handleSubmit}>
            <input
              type="email"
              onChange={handleChange}
              placeholder="Enter your email"
            />{" "}
            <button>{btnText}</button>
          </form>
          <h5 onClick={handleModal}>Skip subscription</h5>
        </section>
      </Content>
    </ModalContainer>
  );
};

export default ConnectedModal;

export const CloseDiv = styled.div`
  width: 27.98px;
  height: 27.98px;
  background: #ffffff;
  position: absolute;
  right: -20px;
  top: -30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  border-radius: 50%;
  @media (max-width: 768px) {
    top: -10px;
    right: -10px;
  }
`;
export const ModalContainer = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(4, 23, 22, 0.5);
  backdrop-filter: blur(15px);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  overflow: hidden;
`;

const Content = styled.div`
  width: 640.58px;
  height: 472.79px;
  z-index: 100;
  background: #ffffff;
  border-radius: 14.2039px;
  position: relative;
  padding: 30px;
  background: url(${img}) no-repeat center/cover;
  position: relative;

  h4 {
    position: absolute;
    top: 86px;
    left: 214px;

    // typography
    font-family: "Gerbil";
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    color: #ffffff;
  }
  h5 {
    margin-top: 30px;
    font-family: "M PLUS 1";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-decoration-line: underline;
    color: rgba(4, 23, 22, 0.75);
  }

  span {
    white-space: nowrap;
    display: inline-block;
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    /* border: 1px solid #000000; */
  }

  section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 195px;
    width: 100%;
    left: 0;

    h6 {
      font-family: "M PLUS 1";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;
      color: #041716;
      width: 254px;
      align-items: center;
      text-align: center;
    }

    p {
      margin-top: 10px;
      font-family: "M PLUS 1";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #1d0f0f;
      width: 398px;
    }
    form {
      margin-top: 36px;
      width: 100%;
      display: flex;
      justify-content: center;

      input[type="email"] {
        width: 414.81px;
        height: 45px;
        border-radius: 35.5097px;
        background: #f9f9f9;
        border: 1px solid #e7e9f0;
        padding-left: 26px;
        ::placeholder {
          font-family: "M PLUS 1";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: rgba(4, 23, 22, 0.5);
        }
      }

      button {
        width: 100.14px;
        height: 45px;
        background: #041716;
        box-shadow: 0px 2.84078px 14.2039px -2.13058px rgba(0, 0, 0, 0.25);
        border-radius: 35.5097px;
        border: none;
        outline: none;
        margin-left: 15px;
        // typography
        font-family: "M PLUS 1";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #ffffff;
      }
    }
  }

  @media (max-width: 768px) {
    min-width: 90%;
    max-width: 355.08px;
    height: 504.25px;
    background-image: url(${img});
    background-repeat: no-repeat;
    background-position: left;

    h4 {
      left: 50%;
      transform: translate(-50%, 0);
    }

    section {
      top: 200px;
      h6 {
        width: 80%;
        text-align: center;
      }
      p {
        width: 80%;
      }
      h5 {
        margin-top: 20px;
      }
      form {
        flex-direction: column;
        align-items: center;
        input[type="email"] {
          min-width: 95%;
          max-width: 314px;
        }
        button {
          margin-top: 20px;
          margin-left: unset;
        }
      }
    }
  }
`;
