import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { EthIcon, MetaMaskIcon, WalletConnectIcon } from "./Icon";
import { flipeet } from "../../lib/flipeet";
import Bg1 from "../../images/backg-1.jpg";
import Bg2 from "../../images/backg-2.jpg";
import Bg3 from "../../images/backg-3.jpg";
import "./Popup.css";
import { ModalContainer } from "../../components/modals/ConnectedModal";
import { Close, MainLogo } from "../../components/icons/Icon";

const ConnectWallet = ({
  isOpen,
  setIsOpen,
  setUser,
  setConnected,
  getUserDetails,
  setText,
}) => {
  const [num, setNum] = useState(0);
  const [className, setClassName] = useState("bade-in-image");
  const handleClick = () => {
    setIsOpen(false);
  };

  const images = [
    {
      id: 1,
      image: Bg1,
    },
    {
      id: 2,
      image: Bg2,
    },
    {
      id: 3,
      image: Bg3,
    },
  ];

  const imageChange = () => {
    const total = images.length - 1;
    setClassName("");

    if (num === total) {
      setNum(0);
    } else {
      setNum(num + 1);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => imageChange(), 5000);
    setClassName("fade-in-image");
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [num]);

  const authUser = async (walletId) => {
    await flipeet.auth
      .authenticate({ provider: walletId })
      .then((response) => setUser(response))
      .then(() => {
        setIsOpen(false);
        setConnected(true);
        setText("Congratulations!");
        getUserDetails();
      })
      .catch((error) => {
        console.log(error);
        setText("Connection failed!");
      });
  };

  const walletData = [
    {
      id: 1,
      name: "Metamask",
      icon: <MetaMaskIcon />,
      provider: "metamask",
    },
    {
      id: 2,
      name: "Wallet Connect",
      icon: <WalletConnectIcon />,
      provider: "walletconnect",
    },
  ];

  return (
    <div>
      {isOpen ? (
        <ModalContainer onClick={handleClick}>
          <Content onClick={(e) => e.stopPropagation()}>
            <CloseDiv onClick={handleClick}>
              <Close />
            </CloseDiv>

            <Section>
              <Top>
                <MainLogo />
                <div onClick={handleClick}>
                  <Close />
                </div>
              </Top>
              <LeftSide className={`left-side `}>
                <div className={`${className}`}>
                  <img src={images[num].image} alt="bg" className="bg-2" />
                </div>
              </LeftSide>
              <RightSide className="">
                <h3>Select a Wallet</h3>
                <p>
                  Connect with one of our available <span>wallet</span>{" "}
                  providers or create a new one.
                </p>

                <div className="ethereum">
                  {" "}
                  <EthIcon />
                  &nbsp; &nbsp; Ethereum
                </div>
                <div>
                  {walletData.map((wallet) => (
                    <div
                      key={wallet.id}
                      className="wallet-connect-item"
                      onClick={() => authUser(wallet.provider)}
                    >
                      <div className="wallet-connect-icon">{wallet.icon}</div>
                      <div className="wallet-connect-name">{wallet.name}</div>
                    </div>
                  ))}
                </div>
              </RightSide>
            </Section>
          </Content>
        </ModalContainer>
      ) : null}
    </div>
  );
};

export const CloseDiv = styled.div`
  width: 27.98px;
  height: 27.98px;
  background: #ffffff;
  position: absolute;
  right: -20px;
  top: -30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  border-radius: 50%;
  @media (max-width: 1024px) {
    display: none;
  }
`;
const Content = styled.div`
  width: 955.55px;
  height: 643.55px;
  z-index: 100;
  background: #ffffff;
  border-radius: 14.2039px;
  position: relative;
  padding: 30px;
  position: relative;
  display: flex;
  // flex-direction: column;

  @media (max-width: 1024px) {
    height: 100%;
    width: 100%;
    padding: 30px;
    border-radius: unset;
  }
`;

const Section = styled.section`
  display: flex;
  width: 100%;

  @media (max-width: 1024px) {
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  .wallet-connect-item {
    width: 100%;
    height: 53.56px;
    display: flex;
    align-items: center;
    background: #ffffff;
    border: 0.75689px solid rgba(255, 174, 36, 0.5);
    border-radius: 7.5689px;
    margin-top: 17px;
    padding-left: 30px;
    cursor: pointer;
    :hover {
      transform: scale(1.05);
    }
  }
  .wallet-connect-name {
    font-family: "M PLUS 1";
    font-style: normal;
    font-weight: 400;
    font-size: 13.6021px;
    line-height: 22px;
    color: #000000;
    padding-left: 21px;
  }
`;

const Top = styled.div`
  display: flex;
  width: 60%;
  height: 50px;
  align-self: flex-end;
  align-items: center;
  justify-content: space-between;
  margin-right: 5%;
  @media (min-width: 1024px) {
    display: none;
  }
`;

const LeftSide = styled.div`
  height: 100%;
  width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  img {
    width: 322.2px;
    height: 368.98px;
    border-radius: 17.735px;
    object-fit: cover;
  }
  @media (max-width: 1024px) {
    width: 90%;
    height: 30%;
    margin-top: 40px;

    img {
      min-width: 90%;
      max-width: 322.2px;
      height: 216px;
    }
  }
`;

const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  height: 90%;
  width: 50%;
  border: 0.85013px solid #e7e9f0;
  box-shadow: 0px 0px 8.5013px #e7e9f0;
  border-radius: 17.0026px;
  padding: 50px 30px;
  text-align: left;
  @media (max-width: 1024px) {
    width: 90%;
    height: 70%;
    border: unset;
    box-shadow: unset;
    border-radius: unset;
    margin-top: 40px;
    // padding: 50px 30px;
    padding: unset;
  }

  h3 {
    font-family: "Gerbil";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 26px;
    color: #041716;
    text-align: left;
  }

  p {
    font-family: "M PLUS 1";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    color: #041716;
  }
  .ethereum {
    background: rgba(231, 233, 240, 0.5);
    width: 119px;
    height: 30.3px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 7px;
    /* filter: blur(2px); */
    border-radius: 9.42535px;
    /* typography */
    font-family: "M PLUS 1";
    font-style: normal;
    font-weight: 400;
    font-size: 7.54028px;
    line-height: 14px;
    text-align: center;
    color: #4d5263;
  }

  span {
    color: rgba(20, 114, 255, 1);
  }
`;
// import
export default ConnectWallet;
