import React from "react";
import styled from "styled-components";
import {
  Close,
  Discord,
  Insta,
  Redit,
  Telegram,
  Twitter,
  Youtube,
} from "../icons/Icon";

export const socials = [
  { id: 1, link: "https://twitter.com/flipeet", icon: <Twitter /> },
  { id: 2, link: "https://instagram.com/flipeet_io", icon: <Insta /> },
  { id: 3, link: "https://www.reddit.com/r/flipeet", icon: <Redit /> },
  { id: 4, link: "https://t.me/+H21xswJS4gwxYjRk", icon: <Telegram /> },
  { id: 5, link: "https://discord.gg/Dk98GmvxeU", icon: <Discord /> },
  {
    id: 6,
    link: "https://youtube.com/channel/UCbYq9UyiuiW4tbQpQ4HVHdA",
    icon: <Youtube />,
  },
];
const ToggleMenu = ({ setToggleMenu, scrolled, user, setIsOpen }) => {
  return (
    <Nav className={`${scrolled && ""}`}>
      <div
        className="flipeet_mobile_nav_close-btn"
        onClick={() => setToggleMenu(false)}
      >
        <Close />
      </div>
      <div className="flipeet_mobile_nav_nav-items">
        {user ? (
          <p className="truncated-text">{user?.ethAddress}</p>
        ) : (
          <Button
            onClick={() => {
              setIsOpen(true);
              setToggleMenu(false);
            }}
          >
            Connect Wallet
          </Button>
        )}
        <h5>Learn how it works</h5>
        <div className="flipeet_mobile_nav_nav_socials">
          <p>Join our community</p>
          <div className="flipeet_mobile_nav_social_icons">
            {socials.map((social) => (
              <a
                href={social.link}
                target="_blank"
                rel="noreferrer"
                key={social.id}
              >
                {social.icon}
              </a>
            ))}
          </div>
        </div>
        <h5>Subscribe</h5>
        <h5 className="flipeet_mobile_nav_margin">FLIPT token</h5>
      </div>
    </Nav>
  );
};

const Nav = styled.nav`
  position: absolute;
  top: 20px;
  right: 0;
  left: 5%;
  background-color: #fff;
  border-radius: 15px;
  width: 90%;
  height: 407px;
  padding: 1rem 1rem;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  z-index: 100;
  .flipeet_mobile_nav_non-sticky {
    top: 6%;
    right: 4%;
    z-index: 1;
  }
  .flipeet_mobile_nav_close-btn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
  }

  .flipeet_mobile_nav_nav-items {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    height: 90%;
  }

  .flipeet_mobile_nav_nav_socials {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-top: 1px solid #cdcdcd;
    border-bottom: 1px solid #cdcdcd;
    margin: 25px 0;
    padding: 25px 5px;

    p {
      font-family: "M PLUS 1";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #586464;
    }
  }

  .flipeet_mobile_nav_social_icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 26px;
  }
  .flipeet_mobile_nav_margin {
    margin-top: 24px;
  }
  h5 {
    font-family: "M PLUS 1";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #041716;
  }
  // svg {
  //   color: red;
  //   background-color: red;
  //   // width: 17px;
  //   // height: 17px;
  //   border: 1px solid blue;
  // }
`;

const Button = styled.button`
  width: 100%;
  height: 45px;
  background: #ffae24;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  margin-bottom: 30px;

  font-family: "M PLUS 1";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;

  @media (min-width: 1024px) {
    display: none;
  }
`;

export default ToggleMenu;
