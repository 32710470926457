import React, { useEffect, useState } from "react";
import Navbar from "../../components/nav-bar/Navbar";
import image from "../../images/nft-bg.png";
import { EthIcon } from "../Popup/Icon";
import { Container, Main, Hero, LeftSide } from "./NewHeaderCss";
import { flipeet } from "../../lib/flipeet";
import ConnectWallet from "../Popup/ConnectWallet";
import MintModal from "../../components/modals/MintModal";
import ConnectedModal from "../../components/modals/ConnectedModal";
import SuscribedModal from "../../components/modals/SuscribedModal";

const NewHeader = () => {
  const [price, setPrice] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [isMintModal, setIsMintModal] = useState(false);
  const [connected, setConnected] = useState(false);
  const [success, setSuccess] = useState(false);
  const [userDetails, setUserDetails] = React.useState(null);
  const [text, setText] = useState("");

  const getPrice = async () => {
    const prices = await flipeet.nativetoken.getMintRate();
    setPrice(prices);
  };

  const getCurrentUser = async () => {
    const user = await flipeet.auth.getCurrentUser();
    setUser(user);
  };
  const getUserDetails = async () => {
    const authenticatedUser =
      await flipeet.apis.token.getConnectedAccountETherBalance();
    setUserDetails(authenticatedUser);

    // localStorage.setItem("userDetail", JSON.stringify(authenticatedUser));
  };

  useEffect(() => {
    getPrice();
    getCurrentUser();
    getUserDetails();
    // setUserDetails(JSON.parse(localStorage.getItem("userDetail")));
    // setUserDetails();
  }, []);

  const handleMintModal = () => {
    setIsMintModal(!isMintModal);
  };

  const scroll = () => {
    const section = document.querySelector("#features");
    section.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <Container>
      <Navbar user={user} setIsOpen={setIsOpen} userDetails={userDetails} />
      <Main>
        <Hero>
          <img src={image} alt="background" />
          <div className="contain">
            <div className="flipeet_nft_price_container">
              {" "}
              <EthIcon /> &nbsp; {price}
            </div>
            <div className="flipeet_nft_title_container">
              <p>TIMELESS </p>
              <h5>Flipeet Community NFT</h5>
            </div>

            <div className="video-container">
              <video
                src="https://ipfs.io/ipfs/QmR1Y6TwgtFZwPRUgv6mwG3YiGivA8hmnUs1ZiqsYmpQU3"
                controls
                loop
                autoPlay
                muted
              />
            </div>

            <button onClick={handleMintModal}>mint</button>
          </div>
        </Hero>
        <LeftSide>
          <h3>Get the NFTs you love with the NFTs you own!</h3>
          <h4>Get NFTs with your NFTs, ERC-20 tokens, or both!</h4>
          <p className="hero-subtex">
            Designed to make NFT to NFT trading possible, and get<br></br> you
            to call the shots on what you value
          </p>
          <button onClick={scroll}>See what we offer</button>
        </LeftSide>
      </Main>
      {isMintModal && (
        <MintModal
          handleMintModal={handleMintModal}
          price={price}
          getUserDetails={getUserDetails}
        />
      )}
      {connected && (
        <ConnectedModal
          setConnected={setConnected}
          user={user}
          setSuccess={setSuccess}
          // setText={setText}
          text={text}
        />
      )}

      {/* < /> */}
      {success && <SuscribedModal setSuccess={setSuccess} />}
      {/* <SuscribedModal setSuccess={setSuccess} /> */}
      <ConnectWallet
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setUser={setUser}
        setConnected={setConnected}
        getUserDetails={getUserDetails}
        setText={setText}
        text={text}
      />
    </Container>
  );
};

export default NewHeader;
