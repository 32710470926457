import React from "react";
import "./Footer.css";
// import Flipeet from '../../images/flipeet.svg'
// import Facebook from '../../images/facebook.png';
import Twitter from "../../images/twitter.svg";
import Discord from "../../images/discord.svg";
// import LinkedIn from '../../images/linkedin.png';
import Instagram from "../../images/instagram.svg";
import { Logo, Youtube, Linkedin } from "../../components/icons/Icon";

const Footer = () => {
  return (
    <footer className="section-padding footer">
      <nav className="nav flex footer-nav">
        <div className="nav-left footer-left">
          {/* <img src={Flipeet} alt='flipeet' /> */}
          <Logo />
        </div>
        <div className="mobile-footer first">
          <p>
            <a href="#/">Privacy Policy</a>
          </p>
          <p>
            <a href="#/">Terms of Use</a>
          </p>
        </div>
        <div className="mobile-footer second">
          <p>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://twitter.com/flipeet"
            >
              <img src={Twitter} alt="flipeet" />
            </a>
          </p>
          <p>
            <a
              target="_blank"
              href="https://discord.gg/Dk98GmvxeU"
              rel="noreferrer"
            >
              <img src={Discord} alt="twitter" />
            </a>
          </p>
          <p>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://instagram.com/flipeet_io"
            >
              <img src={Instagram} alt="flipeet" />
            </a>
          </p>
          {/*<p><a href='#/'><img src={Facebook} alt='flipeet' /></a></p>
          <p><a href='#/'><img src={Instagram} alt='flipeet' /></a></p>
  <p><a href='#/'><img src={LinkedIn} alt='flipeet' /></a></p>*/}
        </div>
        <div className="flex nav-right footer-right">
          <p>
            <a href="#/">Privacy Policy</a>
          </p>
          <p>
            <a href="#/">Terms of Use</a>
          </p>
          <p>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://twitter.com/flipeet"
            >
              <img src={Twitter} alt="flipeet" />
            </a>
          </p>
          <p>
            <a
              target="_blank"
              href="https://discord.gg/Dk98GmvxeU"
              rel="noreferrer"
            >
              <img src={Discord} alt="twitter" />
            </a>
          </p>
          <p>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://instagram.com/flipeet_io"
            >
              {/* <img src={Instagram} alt="flipeet" /> */}
              <Youtube />
            </a>
          </p>
          <p>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://instagram.com/flipeet_io"
            >
              <img src={Instagram} alt="flipeet" />
            </a>
          </p>
          <p>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://instagram.com/flipeet_io"
            >
              {/* <img src={Instagram} alt="flipeet" /> */}
              <Linkedin />
            </a>
          </p>
        </div>
      </nav>
    </footer>
  );
};

export default Footer;
