import React from 'react'
import './Tagline.css'

const Tagline = () => {
  return (
    <section className='tag section-padding'>
      <p>Create. <span className='exchange'>Exchange.</span> Connnect.</p>
    </section>
  )
}

export default Tagline