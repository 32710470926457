import React, { useState } from 'react'
import './Contact.css'
// import Rocket from '../../images/rocket.png'
// import Email from '../../images/mail.png';
// import Numbe from '../../images/Group 555.png'
// import Location from '../../images/Group 556.png'
import Bullet from '../../images/Ellipse 17.png'
import Success from '../../components/success/Success';
import { getFirestore, collection, getDocs, setDoc, doc  } from "firebase/firestore";
import { app } from '../../FirebaseConfig';

const Contact = () => {
  const [value, setValue] = useState('');
  // const [isOpen, setIsOpen] = useState(true);
  const [successModal, setSuccessModal] = useState(false);

  const [btnText, setBtnText] = useState('Submit');

  const db = getFirestore(app);

  const handleChange = (e) => {
    setValue(e.target.value)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    

    setBtnText('Submitting..');

    const subscriptionRef = collection(db, `Subscription`); ///get the booking collection
		const snaps = await getDocs(subscriptionRef); //get the documents in the collection
		console.log(snaps);

		if (!snaps.exists) {
			const createdAt = new Date();

			try {
				console.log('saving...')
				await setDoc(doc(db, 'Subscription', `${value}`),  {
					email: value,
					createdAt
				});
        e.target.reset();
        setSuccessModal(true);
        setBtnText('Submit');
			} catch (error) {
				console.log("Error Creating User", error.message);
			}
		} else {
      alert('already registered');
    }
  }

  // const handleClick = () => {
  //   setSuccessModal(false);
  // }

  return (
    <section className='section-padding contacts'>
      {/*<div className='flex contact-header'>
        <h2>Contact Us</h2>
        <div>
          <img src={Rocket} alt='contact' />
        </div>
      </div>
      <div className='flex contact-modes'>
        <article className='flex'>
          <img src={Email} alt='email' />
          <p>barter@gmail.com</p>
        </article>
        <article className='flex'>
          <img src={Numbe} alt='number' />
          <p>+234 9999994444322</p>
        </article>
        <article className='flex'>
          <img src={Location} alt='location' />
          <p>Enugu, Nigeria</p>
        </article>
  </div>*/}
      <div className='subscription'>
        <div className='subscription-header'>
          <p>Subscribe to Updates!</p>
          <p>Stay up to date with Flipeet's development journey</p>
        </div>
        <form onSubmit={handleSubmit} className='subscription-body'>
          <input type='email' placeholder='Enter your email' onChange={handleChange} />
          <button>{btnText}</button>
        </form>
        <div className='subscription-footer'>
          <p>By clicking on "Submit" you agree to our <span>Privacy Policy</span></p>
          <div className='flex subscription-disclaimer'>
            <div className='flex disclaimer-left'>
              <img src={Bullet} alt='bullets' />
              <p>Be the first to know</p>
            </div>
            <div className='flex disclaimer-right'>
              <img src={Bullet} alt='bullets' />
              <p>Unsubscribe anytime</p>
            </div>
          </div>
        </div>
      </div>
      <div>
      <Success isOpen={successModal} setIsOpen={setSuccessModal}/>
    </div>
    </section>
  )
}

export default Contact