import Flipeet from "@flipeet/sdk";
import "dotenv/config";

// Moralis config
// const config = {
//   appId: "FZ1vaxaXiincgQO4oP0e4U8AjhyPhjeYkJqpuYmt",
//   serverUrl: "https://qzud82gkba5r.usemoralis.com:2053/server",
//   masterKey: "gVWWDZT7yLJKP6L3CWU5HaWgM4doQlTJPRmr5NWp",
//   env: "DEVELOPMENT",
// };
const config = {
  appId: process.env.REACT_APP_APP_ID,
  serverUrl: process.env.REACT_APP_SERVER_URL,
  masterKey: process.env.REACT_APP_MASTER_KEY,
  env: "DEVELOPMENT",
  // env: "PRODUCTION" | "STAGING" | "DEVELOPMENT",
};
// Initialize Flipeet
export const flipeet = new Flipeet(config);
