import styled from "styled-components";
const ModalContainer = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(4, 23, 22, 0.5);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  @media (max-width: 999px) {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    height: unset;
  }
`;

const ModalContent = styled.div`
  display: none;
  max-width: 955px;
  width: 955.55px;
  height: 442.44px;
  background: #ffffff;
  box-shadow: 0px 3.74266px 31.8126px 2.80699px rgba(0, 0, 0, 0.05);
  border-radius: 18.7133px;
  position: relative;
  padding: 30px;
  display: flex;

  @media (max-width: 999px) {
    display: none;
  }
`;

const CloseBtn = styled.button`
  width: 27.98px;
  height: 27.98px;
  background: #ffffff;
  position: absolute;
  right: -20px;
  top: -40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  border-radius: 50%;
`;
const LeftSide = styled.div`
  width: 55%;
  height: 100%;
  padding-right: 30px;

  .flipeet__video_container-div {
    filter: drop-shadow(0px 0px 16.0498px rgba(225, 225, 225, 0.8));
    border-radius: 17.735px;
    width: 100%;
    height: 287.36px;
    position: relative;
    overflow: hidden;

    video {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0px;
      right: 0px;
      object-fit: cover;
    }
  }

  .flipeet__mint_modal_nft_price {
    font-family: "M PLUS 1";
    font-style: normal;
    font-weight: 600;
    font-size: 16.469px;
    line-height: 25px;
    text-align: left;
    color: #041716;
    margin-top: 12px;
  }
  .flipeet__mint_modal_nft_collection {
    width: 100%;
    height: 27.35px;
    margin-top: 17px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h6 {
      font-family: "M PLUS 1";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      color: #586464;
    }

    p {
      font-family: "M PLUS 1";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #041716;
    }
    & > div {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: space-between;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    padding-right: unset;

    .flipeet__mint_modal_nft_collection {
      flex-direction: column;
    }
  }
`;
const RightSide = styled.div`
  width: 45%;
  height: 100%;
  padding-left: 30px;
  position: relative;
  display: flex;

  @media (max-width: 768px) {
    width: 100%;
  }
  flex-direction: column;
  .flipeet__flex_class {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  span {
    width: 184.46px;
    display: flex;
    align-items: center;
    text-align: center;
    height: 25px;
    padding: 0 10px;
    background: #e7e9f0;
    border-radius: 10px;
    font-family: "M PLUS 1";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: rgba(4, 23, 22, 0.75);
  }

  h3 {
    font-family: "M PLUS 1";
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 31px;
    color: #041716;
  }
  h5 {
    font-family: "M PLUS 1";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: rgba(4, 23, 22, 0.5);
  }
  small {
    font-family: "M PLUS 1";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: rgba(4, 23, 22, 0.75);
  }
`;

const UseCase = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 28px;
  h4 {
    font-family: "M PLUS 1";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #041716;
  }

  ul {
    margin-top: 10px;
  }

  li {
    font-family: "M PLUS 1";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #041716;
  }
`;

const MintSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  // margin-bottom: 20px;
  justify-self: flex-end;
  margin-top: 120px;

  .flipeet_nft_control_btn {
    display: flex;
    align-items: center;
    h6 {
      font-family: "M PLUS 1";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: rgba(4, 23, 22, 0.5);
    }

    aside {
      width: 92.66px;
      height: 29.07px;
      background: #f9f9f9;
      border: 0.64605px solid #e7e9f0;
      border-radius: 12.921px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;

      p {
        font-family: "M PLUS 1";
        font-style: normal;
        font-weight: 400;
        font-size: 11.6289px;
        line-height: 16px;
        text-align: center;
        color: #041716;
      }
    }
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 14px;
    width: 352px;
    width: 100%;
    height: 40px;
    background: #ffae24;
    border-radius: 15px;
    border: none;
    font-family: "M PLUS 1";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
  }
`;

// mobile content
const MobileContent = styled.div`
  overflow: scroll;
  position: relative;
  background: #ffffff;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.875rem;
  font-family: "M PLUS 1";
  font-style: normal;
  aside {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 1.25rem;
    height: 71.28px;
    & div {
      display: flex;
      align-items: center;
      //   justify-content: center;
      width: 100%;
    }
    h6 {
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 20px;
      color: #586464;
    }
    p {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 20px;
      color: #041716;
    }
  }
  .flipeet_mobile_popup_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    span {
      width: 184.46px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #e7e9f0;
      border-radius: 10px;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      color: rgba(4, 23, 22, 0.75);
    }
  }

  .flipeet_mobile_popup_top_section {
    // display: flex;
    width: full;
    margin-top: 1.25rem;
    padding-bottom: 1.25rem;
    border-bottom: 1px solid #e7e9f0;
  }

  .flipeet__mobile_popup_video_container_div {
    margin: 10px 0;
    filter: drop-shadow(0px 0px 12.0934px rgba(225, 225, 225, 0.8));
    border-radius: 13.3632px;
    width: 100%;
    height: 216.52px;
    position: relative;
    overflow: hidden;

    video {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0px;
      right: 0px;
      object-fit: fill;
    }
  }
  h3 {
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 26px;
    color: #041716;

    span {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      color: rgba(4, 23, 22, 0.5);
    }
  }
  h6 {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #041716;
  }
  small {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: rgba(4, 23, 22, 0.75);
  }
  @media (min-width: 1000px) {
    display: none;
  }

  .flipeet_mobile_popup_bottom_section {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    h4 {
      font-weight: 700;
      font-size: 20px;
      line-height: 26px;
      color: #041716;
    }
    ul {
      margin-top: 10px;
      padding: 0 20px;
      li {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #041716;
      }
    }
  }

  footer {
  }
`;

const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1.25rem 0;
  align-self: flex-end;
  width: 100%;
  //   position: absolute;
  //   bottom: 0;
  //   left: 0;
  //   right: 0;
  //   padding: 0 1.25rem;
  button {
    width: 100%;
    height: 40px;
    background: #ffae24;
    border-radius: 15px;
    margin-top: 25px;
    border: none;
    outline: none;
    // typography
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
  }
  aside {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 130.69px;
    height: 40px;
    background: #f9f9f9;
    border: 0.911148px solid #e7e9f0;
    border-radius: 20px;

    div {
      display: flex;
      justify-content: center;
    }
  }
  & > section {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  .flipeet_mobile_popup_nft_details {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 5px;
    h5 {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: rgba(4, 23, 22, 0.5);
    }
    h6 {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #041716;
    }
  }
`;

export {
  ModalContainer,
  ModalContent,
  CloseBtn,
  LeftSide,
  RightSide,
  UseCase,
  MintSection,
  MobileContent,
  Footer,
};
