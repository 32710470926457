import React from "react";
import styled from "styled-components";
import { CloseIcon } from "../../container/Popup/Icon";
import { flipeet } from "../../lib/flipeet";
import { Copy } from "../icons/Icon";

const UserDetailPopUP = ({ userDetails, user, handleUserDetailsModal }) => {
  const truncatedAddress = flipeet.utils.getEllipsisTxt(user?.ethAddress, 6);
  const handleLogout = () => {
    flipeet.auth.logout();
    localStorage.removeItem("userDetail");
    window.location.reload();
  };
  return (
    <Container>
      <aside>
        <div onClick={handleUserDetailsModal}>
          <CloseIcon />
        </div>
      </aside>
      <InnerDiv>
        <Section1>
          <img src={userDetails?.logo} alt="" />
          <div>
            <p>{userDetails?.name}</p>
            <h6>{truncatedAddress} </h6>
          </div>

          <div onClick={() => navigator.clipboard.writeText(user?.ethAddress)}>
            <Copy />
          </div>
        </Section1>
        <Section2>
          <p>Total Balance</p>
          <h6>{userDetails?.formatted_usd_balance}</h6>
        </Section2>
        <Button onClick={handleLogout}>Disconnect</Button>
      </InnerDiv>
    </Container>
  );
};

const Container = styled.div`
  z-index: 100;
  width: 290px;
  height: 262px;
  position: fixed;
  top: 80px;
  right: 70px;
  background: #ffffff;
  box-shadow: 0px 12px 20px 3px rgba(0, 0, 0, 0.15);
  border-radius: 17px 17px 17.0026px 17.0026px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 25px 17px;
  font-family: "M PLUS 1";
  font-style: normal;

  aside {
    display: none;
  }

  @media (max-width: 798px) {
    height: 302px;
    width: 90%;
    max-width: 385px;
    right: 5%;

    aside {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 25px;
    }
  }
`;

const InnerDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #041716;
  }
  h6 {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #041716;
  }
`;

const Section1 = styled.div`
  width: 100%;
  height: 47px;
  display: flex;
  align-items: center;
  div {
    margin-right: 30px;
  }
  img {
    width: 47px;
    height: 47px;
    border-radius: 50%;
    margin-right: 10px;
    border: 1px solid #ffae24;
  }
`;

const Section2 = styled.div`
  width: 100%;
  height: 72px;
  border: 1px solid #e7e9f0;
  border-radius: 10px;
  padding: 13px 17px;
  margin-top: 35px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Button = styled.button`
  width: 100%;
  height: 40px;
  background: #ffae24;
  border-radius: 15px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  margin-top: 20px;
  border: none;
  cursor: pointer;
  outline: none;
  :hover {
    transform: scale(1.05);
  }
`;

export default UserDetailPopUP;
