import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Hamburger from "../../images/hamburger.svg";
import { DropDown, Logo } from "../icons/Icon";
import UserDetailPopUP from "../modals/UserDetailPopUP";
import ToggleMenu, { socials } from "./ToggleMenu";

const Navbar = ({ user, setIsOpen, userDetails }) => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [userDetailsModal, setUserDetailsModal] = useState(false);
  const handleMenuClick = () => {
    user ? setUserDetailsModal(true) : setToggleMenu(true);
  };
  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 200) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const handleUserDetailsModal = () => {
    setUserDetailsModal(!userDetailsModal);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <Nav scrolled={scrolled}>
      <nav className={`${scrolled ? "white-bg" : "transparent-bg"}`}>
        <div className="">
          <Logo />
        </div>

        <RightSide>
          <p className="item">Learn how it works</p>

          <div className="dropdown">
            <p className="item">
              Join our community &nbsp;
              <DropDown />
            </p>
            <div className="dropdown-item">
              <div className="flipeet_desktop_nav_social_icons">
                {socials.map((social) => (
                  <a
                    href={social.link}
                    target="_blank"
                    rel="noreferrer"
                    key={social.id}
                  >
                    {social.icon}
                  </a>
                ))}
              </div>
              <h6>Subscribe</h6>
              <h6>FLIPT token</h6>
            </div>
          </div>

          <div>
            {user ? (
              <Div onClick={handleUserDetailsModal}>
                <img src={userDetails?.logo} alt="" />
                My Profile
                <DropDown />
              </Div>
            ) : (
              <div>
                <BigButton
                  onClick={() => {
                    setIsOpen(true);
                  }}
                >
                  Connect Wallet
                </BigButton>
              </div>
            )}
          </div>
        </RightSide>
        <div className="flipeet_mobile_nav_icon" onClick={handleMenuClick}>
          <img src={Hamburger} alt="mobile nav icon" />
        </div>
        {toggleMenu && (
          <ToggleMenu
            setToggleMenu={setToggleMenu}
            scrolled={scrolled}
            user={user}
            setIsOpen={setIsOpen}
          />
        )}
      </nav>

      {userDetailsModal && (
        <UserDetailPopUP
          userDetails={userDetails}
          user={user}
          handleUserDetailsModal={handleUserDetailsModal}
        />
      )}
    </Nav>
  );
};

const Div = styled.div`
  width: 156px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  display: flex;
  cursor: pointer;
  font-family: "M PLUS 1";
  img {
    width: 33px;
    height: 33px;
    border: 1px solid #ffae24;
    border-radius: 3.9521px;
  }
`;

const Nav = styled.div`
  //   height: 100px;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  z-index: 10;
  justify-content: space-between;
  align-items: start;
  width: 100%;
  & > nav {
    padding: 0 30px;
    height: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    // max-width: 1440px;
  }
  .truncated-text {
    white-space: nowrap;
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    /* border: 1px solid #000000; */
  }
  .transparent-bg {
    background: transparent;
  }
  .white-bg {
    background: #fff;
    position: fixed;
    z-index: 1;
  }

  @media (min-width: 768px) {
    & > nav {
      padding: 0 50px;
    }
  }

  @media (min-width: 1024px) {
    & > nav {
      padding: 0 80px;
    }
    .flipeet_mobile_nav_icon {
      display: none;
    }
  }
`;

const RightSide = styled.div`
  display: none;
  @media (min-width: 1024px) {
    // width: 65%;
    height: 100%;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    .dropdown {
      cursor: pointer;
      position: relative;
    }
    p {
      display: flex;
      align-items: center;
      position: relative;
      font-family: "M PLUS 1";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #000000;
      margin-right: 50px;
    }
    .flipeet_desktop_nav_social_icons {
      width: 100%;
      height: 30px;
      display: flex;
      padding-bottom: 5px;
      padding-left: 7px;
      padding-right: 7px;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #cdcdcd;

      // & svg {
      //   width: 22px;
      //   height: 22px;
      // }
    }

    .dropdown .dropdown-item {
      display: none;
      color: #000000;
      background: #ffffff;
      width: 312.85px;
      height: 223.84px;
      box-shadow: 0px 12px 20px 3px rgba(0, 0, 0, 0.15);
      border-radius: 15px;
      position: absolute;
      z-index: 10;
      padding: 30px 20px;

      h6 {
        height: 56px;
        display: flex;
        align-items: center;
        margin-top: 20px;
        font-family: "Neris";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #000000;
        cursor: pointer;
        padding-left: 20px;
      }
      h6:hover {
        background: #e7e9f0;
        border-radius: 15px;
      }
    }
    .dropdown:hover .dropdown-item {
      display: block;
      // h6 {
      //   background: #e7e9f0;
      //   border-radius: 15px;
      // }
    }
  }

  @media (min-width: 1280px) {
    width: unset;
    p {
      margin-right: 50px;
    }
  }
`;

const BigButton = styled.button`
  width: 175px;
  height: 45px;
  left: 1237.52px;
  top: 22.5px;
  background: #ffae24;
  border-radius: 20px;
  border: none;
  outline: none;
  font-family: "M PLUS 1";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  :hover {
    transform: scale(1.05);
  }
`;
export default Navbar;
