const EthIcon = () => (
  <svg
    width="9"
    height="15"
    viewBox="0 0 9 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M-0.000244141 7.13865L4.379 9.68184V5.18306V-0.000244141L-0.000244141 7.13865Z"
      fill="#898989"
    />
    <path
      d="M4.37915 -0.000244141V5.18306V9.68184L8.7584 7.13865L4.37915 -0.000244141Z"
      fill="#323232"
    />
    <path
      d="M4.38037 5.18359L8.75832 7.13858L4.38037 9.68111V5.18359Z"
      fill="#141414"
    />
    <path
      d="M4.37925 5.18311L4.38055 5.18354V9.68062L4.37925 9.68189L0 7.1387L4.37925 5.18311Z"
      fill="#373737"
    />
    <path d="M0 7.95435L4.37925 14.0173V10.4963L0 7.95435Z" fill="#898989" />
    <path
      d="M4.37915 10.4963V14.0173L8.761 7.95435L4.37915 10.4963Z"
      fill="#323232"
    />
  </svg>
);
const CloseIcon = () => (
  <svg
    width="10"
    height="12"
    viewBox="0 0 10 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      x1="1.20343"
      y1="1.94895"
      x2="8.97119"
      y2="10.506"
      stroke="#041716"
      strokeWidth="1.20752"
      strokeLinecap="round"
    />
    <line
      x1="8.97099"
      y1="1.62552"
      x2="1.20274"
      y2="10.1942"
      stroke="#041716"
      strokeWidth="1.20752"
      strokeLinecap="round"
    />
  </svg>
);
const WalletConnectIcon = () => (
  <svg
    width="25"
    height="16"
    viewBox="0 0 25 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.2356 3.87063C15.3537 -0.00505221 9.05999 -0.00505221 5.17813 3.87063L4.66535 4.38259C4.47126 4.57637 4.47126 4.89056 4.66535 5.08434L6.26351 6.67996C6.36056 6.77685 6.5179 6.77685 6.61495 6.67996L7.30345 5.99255C10.0115 3.28878 14.4022 3.28878 17.1103 5.99255L17.7532 6.63444C17.8502 6.73133 18.0076 6.73133 18.1046 6.63444L19.7028 5.03882C19.8969 4.84504 19.8969 4.53085 19.7028 4.33707L19.2356 3.87063ZM23.9632 8.59061L22.5409 7.17051C22.3468 6.97672 22.0321 6.97672 21.838 7.17051L17.2861 11.7152C17.2375 11.7636 17.1589 11.7636 17.1104 11.7152L12.5584 7.17042L12.5584 7.17041C12.3643 6.97663 12.0496 6.97664 11.8555 7.17042L7.30371 11.7152C7.25518 11.7636 7.17651 11.7636 7.12799 11.7152L2.57598 7.17042C2.38189 6.97664 2.0672 6.97664 1.87311 7.17042L0.450745 8.59052C0.256653 8.78431 0.256653 9.09849 0.450745 9.29228L6.86446 15.6958C7.05855 15.8896 7.37324 15.8896 7.56733 15.6958L12.1192 11.1511C12.1677 11.1026 12.2464 11.1026 12.2949 11.1511L16.8468 15.6958C17.0409 15.8896 17.3556 15.8896 17.5497 15.6958L23.9632 9.29236C24.1573 9.09857 24.1573 8.78439 23.9632 8.59061Z"
      fill="#3B99FC"
    />
  </svg>
);
const MetaMaskIcon = () => (
  <svg
    width="26"
    height="24"
    viewBox="0 0 26 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.039 0.302246L14.408 7.52502L16.1989 3.2684L24.039 0.302246Z"
      fill="#E17726"
      stroke="#E17726"
      strokeWidth="0.192607"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.82397 0.302246L11.3692 7.59243L9.66405 3.2684L1.82397 0.302246Z"
      fill="#E27625"
      stroke="#E27625"
      strokeWidth="0.192607"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.5706 17.0496L18.0081 21.0173L23.4951 22.5485L25.067 17.1363L20.5706 17.0496Z"
      fill="#E27625"
      stroke="#E27625"
      strokeWidth="0.192607"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.804932 17.1363L2.36722 22.5485L7.8448 21.0173L5.29176 17.0496L0.804932 17.1363Z"
      fill="#E27625"
      stroke="#E27625"
      strokeWidth="0.192607"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.54931 10.3466L6.02515 12.6772L11.4551 12.9276L11.2741 7.00488L7.54931 10.3466Z"
      fill="#E27625"
      stroke="#E27625"
      strokeWidth="0.192607"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.3136 10.3466L14.5318 6.9375L14.408 12.9276L19.8378 12.6772L18.3136 10.3466Z"
      fill="#E27625"
      stroke="#E27625"
      strokeWidth="0.192607"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.84473 21.017L11.1312 19.4088L8.302 17.1746L7.84473 21.017Z"
      fill="#E27625"
      stroke="#E27625"
      strokeWidth="0.192607"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity="0.8"
      d="M14.7319 19.4088L18.0089 21.017L17.5612 17.1746L14.7319 19.4088Z"
      fill="#E27625"
      stroke="#E27625"
      strokeWidth="0.192607"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.0089 21.0172L14.7319 19.4089L14.9986 21.5661L14.97 22.481L18.0089 21.0172Z"
      fill="#D5BFB2"
      stroke="#D5BFB2"
      strokeWidth="0.192607"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.84473 21.0172L10.8931 22.481L10.8741 21.5661L11.1312 19.4089L7.84473 21.0172Z"
      fill="#D5BFB2"
      stroke="#D5BFB2"
      strokeWidth="0.192607"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.9501 15.7492L8.22559 14.9403L10.1499 14.0447L10.9501 15.7492Z"
      fill="#233447"
      stroke="#233447"
      strokeWidth="0.192607"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.9128 15.7492L15.7131 14.0447L17.6469 14.9403L14.9128 15.7492Z"
      fill="#233447"
      stroke="#233447"
      strokeWidth="0.192607"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.84499 21.0173L8.32133 17.0496L5.29199 17.1363L7.84499 21.0173Z"
      fill="#CC6228"
      stroke="#CC6228"
      strokeWidth="0.192607"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5425 17.0496L18.0093 21.0173L20.5718 17.1363L17.5425 17.0496Z"
      fill="#CC6228"
      stroke="#CC6228"
      strokeWidth="0.192607"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.8378 12.6772L14.408 12.9276L14.9128 15.7494L15.7131 14.0448L17.6469 14.9404L19.8378 12.6772Z"
      fill="#CC6228"
      stroke="#CC6228"
      strokeWidth="0.192607"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.22569 14.9404L10.15 14.0448L10.9502 15.7494L11.4551 12.9276L6.02515 12.6772L8.22569 14.9404Z"
      fill="#CC6228"
      stroke="#CC6228"
      strokeWidth="0.192607"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.02515 12.6772L8.30192 17.1747L8.22566 14.9404L6.02515 12.6772Z"
      fill="#E27525"
      stroke="#E27525"
      strokeWidth="0.192607"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.6472 14.9404L17.5615 17.1747L19.8383 12.6772L17.6472 14.9404Z"
      fill="#E27525"
      stroke="#E27525"
      strokeWidth="0.192607"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.4554 12.9277L10.9504 15.7495L11.5887 19.0815L11.7316 14.6901L11.4554 12.9277Z"
      fill="#E27525"
      stroke="#E27525"
      strokeWidth="0.192607"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.4076 12.9277L14.1409 14.6805L14.2743 19.0815L14.9125 15.7495L14.4076 12.9277Z"
      fill="#E27525"
      stroke="#E27525"
      strokeWidth="0.192607"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.9129 15.7492L14.2747 19.0812L14.7319 19.4087L17.5612 17.1744L17.6469 14.9402L14.9129 15.7492Z"
      fill="#F5841F"
      stroke="#F5841F"
      strokeWidth="0.192607"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.22559 14.9402L8.30185 17.1744L11.1311 19.4087L11.5883 19.0812L10.9501 15.7492L8.22559 14.9402Z"
      fill="#F5841F"
      stroke="#F5841F"
      strokeWidth="0.192607"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.9703 22.4809L14.9988 21.566L14.7512 21.3541H11.1122L10.8741 21.566L10.8931 22.4809L7.84473 21.0171L8.91165 21.9031L11.0741 23.4151H14.7797L16.9517 21.9031L18.0091 21.0171L14.9703 22.4809Z"
      fill="#C0AC9D"
      stroke="#C0AC9D"
      strokeWidth="0.192607"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.7324 19.4087L14.2751 19.0813H11.5887L11.1315 19.4087L10.8743 21.5659L11.1124 21.354H14.7514L14.9991 21.5659L14.7324 19.4087Z"
      fill="#161616"
      stroke="#161616"
      strokeWidth="0.192607"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.4486 7.99688L25.2583 4.00994L24.039 0.302246L14.7319 7.28429L18.3137 10.3467L23.3721 11.8394L24.4867 10.5201L24.0009 10.1637L24.7725 9.45113L24.1819 8.98885L24.9535 8.39175L24.4486 7.99688Z"
      fill="#763E1A"
      stroke="#763E1A"
      strokeWidth="0.192607"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.604492 4.00994L1.42374 7.99688L0.899807 8.39175L1.68095 8.98885L1.09033 9.45113L1.86195 10.1637L1.37611 10.5201L2.49068 11.8394L7.54912 10.3467L11.1309 7.28429L1.82385 0.302246L0.604492 4.00994Z"
      fill="#763E1A"
      stroke="#763E1A"
      strokeWidth="0.192607"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.3725 11.8391L18.3141 10.3464L19.8383 12.677L17.5615 17.1743L20.5718 17.1358H25.0681L23.3725 11.8391Z"
      fill="#F5841F"
      stroke="#F5841F"
      strokeWidth="0.192607"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.54944 10.3464L2.49106 11.8391L0.804932 17.1358H5.29176L8.302 17.1743L6.02528 12.677L7.54944 10.3464Z"
      fill="#F5841F"
      stroke="#F5841F"
      strokeWidth="0.192607"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.4081 12.9276L14.732 7.28414L16.199 3.26831H9.66406L11.1311 7.28414L11.455 12.9276L11.5788 14.6995L11.5883 19.0814H14.2747L14.2843 14.6995L14.4081 12.9276Z"
      fill="#F5841F"
      stroke="#F5841F"
      strokeWidth="0.192607"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export { EthIcon, MetaMaskIcon, WalletConnectIcon, CloseIcon };
